/* MomentDetail.css */

.post-card {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
  }
  
  .profile-pic {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  
  .post-image {
    height: 600px ;
    width: 100%;
    border-radius: 10px;
  }
  
  .icon {
    font-size: 1.2rem;
    margin-right: 5px;
  }
  
  hr {
    margin: 10px 0;
  }
  
  @media (max-width: 576px) {
    .profile-pic {
      width: 40px;
      height: 40px;
    }
  
    .icon {
      font-size: 1rem;
    }
  }
  