.chat-list {
    border-right: 1px solid #ddd;
    height: 100vh;
    overflow-y: auto;
  }
  
  .chat-window {
    height: 80vh;
    display: flex;
    flex-direction: column;
  }
  
  .message-input {
    margin-top: auto;
  }
  
  .chat-list-container {
    padding: 0;
  }
  
  .chat-window-container {
    padding: 0;
  }
  
  .chat-window .list-group-item {
    border: none;
  }
  
  .chat-window .list-group-item.text-end {
    text-align: right;
  }
  