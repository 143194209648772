/* Banner Section */
.banner {
    width: 100%;
    max-height: 200px; /* Increased height for a more prominent banner */
    object-fit: cover;
}

/* Profile Heading */
.profile-heading {
    font-size: 30px;
    padding: 20px 10px; /* Added padding for better spacing */
    font-family: 'Arial', sans-serif; /* Specified a common web-safe font */
    font-weight: bold;
    text-align: center; /* Centered the heading */
}

/* Profile Image Section */
.profile-image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Increased padding for more space around the image */
}

/* Avatar Image */
.profile-avatar-image {
    height: 120px;
    width: 120px;
    object-fit: cover; /* Ensures the image is properly contained */
    border-radius: 50%; /* Ensures the avatar is circular */
    border: 4px solid #fff; /* Added border for better contrast */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Edit Button */
.profile-avatar-image-edit {
    background-color: #fff;
    color: rgb(5, 138, 247);
    padding: 8px 12px; /* Adjusted padding for better button dimensions */
    margin-top: 10px; /* Reduced margin for closer proximity to avatar */
    border: 2px solid rgb(5, 138, 247); /* Added border for better visual integration */
    font-weight: bold;
    font-size: 14px; /* Adjusted font size for better readability */
    border-radius: 5px; /* Rounded corners for a modern look */
    transition: all 0.3s ease; /* Smooth transition on hover */
}

.profile-avatar-image-edit:hover,
.profile-avatar-image-edit:active {
    background-color: rgb(5, 138, 247);
    color: #fff;
    transform: scale(1.05); /* Slight scaling effect on hover */
}

/* Profile Info Section */
.profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.profile-info span {
    font-size: 16px; /* Standardized text size */
    margin-bottom: 10px; /* Added space between labels and values */
    display: inline-block; /* Ensured block alignment */
}

.profile-info Button {
    margin-left: 10px; /* Spaced out the edit button */
    font-size: 14px; /* Consistent font size */
}

/* Card Section (Optional for Profile Details) */
.card {
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
    margin-bottom: 20px; /* Added margin for spacing between cards */
}

.card-body {
    padding: 20px;
}
