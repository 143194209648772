.post-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-card:hover {
  transform: scale(1.03);
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.profile-pic {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.post-image {
  max-height: 300px;
  object-fit: cover;
}

.card-body {
  text-decoration: none;
  flex-grow: 1;
}

.list-group-item {
  border: none;
}

.icon {
  margin-right: 5px;
}

a, a:hover, a:focus {
  text-decoration: none;
  color: inherit;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.card-text {
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.text-muted {
  font-size: 0.875rem;
  color: #888;
}
